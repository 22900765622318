import React from "react"

const Footer = () => (
  <React.Fragment>
    <nav style={{position: 'relative'}}>
        <div className="has-text-centered" style={{padding: '0.5rem', width: '100%', backgroundColor: '#435675', color: 'white', minHeight: '10vh'}}>
            Copyright &copy; 2019 - {process.env.GATSBY_AGE_COPYRIGHT} Γενικό Επιτελείο Εθνικής Άμυνας <br />
            Ανάπτυξη: Γενικό Επιτελείο Αεροπορίας/Κέντρο Μηχανογράφησης <br />
            Με την επιφύλαξη παντός δικαιώματος.
        </div>
    </nav> 
  </React.Fragment>
)

export default Footer