import React from "react"
import ellinikiDimokratiaLogo from "../assets/img/ethnosimo.png"
import geethaLogo from "../assets/img/geetha.png"
import baseApplication from '../data/pdf/applications/BaseApplication.pdf';
import healthHistory from '../data/pdf/applications/HealthHistory.pdf';
import healthExamination from '../data/pdf/applications/HealthExamination.pdf';

const Header = () => (
  <React.Fragment>
    <div className="hero is-light">
      <div style={{ paddingTop: "2em", paddingLeft: "2em" }}>
        <nav className="level is-mobile">
          <div className="level-item has-text-centered">
            <figure
              style={{
                verticalAlign: "top",
                display: "inline-block",
                textAlign: "center",
                width: "120px",
              }}
            >
              <img src={ellinikiDimokratiaLogo} alt="" />
              <figcaption style={{ display: "block", fontSize: "small" }}>
                Ελληνική Δημοκρατία
              </figcaption>
            </figure>
          </div>
          <div className="level-item has-text-centered">
            <figure
              style={{
                verticalAlign: "top",
                display: "inline-block",
                textAlign: "center",
                width: "120px",
              }}
            >
              <img src={geethaLogo} style={{ width: "62px" }} alt="" />
              <figcaption style={{ display: "block", fontSize: "small" }}>
                Γενικό Επιτελείο Εθνικής Άμυνας
              </figcaption>
            </figure>
          </div>
        </nav>
      </div>
      <div className="hero-body">
        <div className="has-text-centered">
          <h2 className="subtitle is-6 has-text-weight-bold">
            Σύστημα Υποβολής Αίτησης Κατάταξης Σε Στρατιωτικές Σχολές
          </h2>
          <a
            className="button is-link"
            href={process.env.GATSBY_APPLICATION_URL}
            target="_self"
          >
            Είσοδος στην εφαρμογή
          </a>
        </div>

        
        <div className="has-text-centered" style={{marginTop:"20px"}}>
          <h4 style={{color:"red"}}>Χρήσιμα Έντυπα</h4>
          <ul className="list-inline" >
            <a href={baseApplication} style={{marginRight:"20px",color:"blue"}} target="_blank"><b>Αίτηση Υποψηφίου</b></a>
            <a href={healthHistory} style={{marginRight:"20px",color:"blue"}}  target="_blank"><b>Δελτίο Ιατρικού Ιστορικού</b></a>
            <a href={healthExamination} style={{marginRight:"20px",color:"blue"}} target="_blank"><b>Δελτίο Υγειονομικής Κατάστασης</b></a>
          </ul>
        </div>
      </div>
    </div>
  </React.Fragment>
)

export default Header
