module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Σύστημα Υποβολής Αίτησης Κατάταξης Σε Στρατιωτικές Σχολές","short_name":"Σύστημα Υποβολής Αίτησης Κατάταξης Σε Στρατιωτικές Σχολές","start_url":"/","background_color":"#28339E","theme_color":"#28339E","display":"minimal-ui","icon":"src/data/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9dcdb228089b9e84eebafbb179ba13ee"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
