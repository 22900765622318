/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import BurgerButton from './burgerButton';
import ExternalLayout from "./externalLayout"
import Footer from './footer';
import Helmet from 'react-helmet'
//import "./layout.css"
import "../assets/css/bulma.min.css";
import "../assets/css/highlight.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bulma-timeline/dist/css/bulma-timeline.min.css";
import "../assets/css/custom.css";

function Layout ({ children, location }) {

  return(
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Helmet>
            <meta name="author" content="ΓΕΑ/ΚΜΗ" />
            <meta name="keywords" content="ΑΣΕΙ, ΑΣΣΥ, στρατιωτικές σχολές" />
            {/*<body className="has-navbar-fixed-bottom" />*/}
            <style type="text/css">{`
              body {
                padding-bottom: 0px !important;
                overflow: auto;
              }
            `}
            </style>
          </Helmet>
          <header>
            <BurgerButton location={location.pathname} />
            <div className="columns is-marginless" style={{overflowY: 'initial', minHeight: '90vh',height: 'initial'}}>
              <ExternalLayout siteTitle={data.site.siteMetadata.title} children={children} location={location.pathname} />
              
            </div>
          </header>
            
          <Footer />
        </React.Fragment>
      )}
    />
  );
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
