import React, { useState } from "react"
import { Link } from "gatsby"

function BurgerButton({location}){
    const [burgerButtonIsActive, changeBurgerButtonState] = useState(false);
    const [navBarSubMenuIsActive, changeNavBarSubMenuState] = useState(false);
  
  return(
    <React.Fragment>
        <nav className="navbar has-background-light is-hidden-desktop" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
            <a role="button" onClick={() => changeBurgerButtonState(!burgerButtonIsActive)} className={`navbar-burger has-background-light ${burgerButtonIsActive === true ? 'is-active' : ''}`} data-target="navMenu" aria-label="menu" aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
            </div>
            <div id="navMenu" className={`navbar-menu ${burgerButtonIsActive === true ? 'is-active' : ''}`} style={{position: 'fixed', zIndex: 100, width: '100%'}}>
            <div className="navbar-start">
                <Link to="/" className={`navbar-item ${location === '/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Αρχική</Link>
                <div className="navbar-item has-dropdown is-hoverable">
                    <a className="navbar-link" onClick={() => changeNavBarSubMenuState(!navBarSubMenuIsActive)} role="button">
                        Εγκύκλιος
                    </a>
                    <div className="navbar-dropdown" style={{display: navBarSubMenuIsActive === true ? 'block' : 'none'}}>
                        <Link to="/bulletin/" className={`navbar-item ${location === '/bulletin/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Κείμενο</Link>
                        <Link to="/timetable/" className={`navbar-item ${location === '/timetable/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Χρονοδιάγραμμα</Link>
                        <Link to="/faq/" className={`navbar-item ${location === '/faq/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Συχνές Ερωτήσεις</Link>
                    </div>
                </div>
                <Link to="/application-information/" className={`navbar-item ${location === '/application-information/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Συμπλήρωση Αίτησης</Link>
                <Link to="/english-certificates/" className={`navbar-item ${location === '/english-certificates/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Πτυχία Αγγλικών</Link>
                <Link to="/legislature/" className={`navbar-item ${location === '/legislature/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Νομοθεσία</Link>
                <Link to="/contact/" className={`navbar-item ${location === '/contact/' ? 'is-active' : '' } `} onClick={() => changeBurgerButtonState(!burgerButtonIsActive)}>Επικοινωνία</Link>
            </div>
            </div>
        </nav>
    </React.Fragment>
  );
}

export default BurgerButton
