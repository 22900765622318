// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-information-js": () => import("./../../../src/pages/application-information.js" /* webpackChunkName: "component---src-pages-application-information-js" */),
  "component---src-pages-bulletin-js": () => import("./../../../src/pages/bulletin.js" /* webpackChunkName: "component---src-pages-bulletin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-english-certificates-js": () => import("./../../../src/pages/english-certificates.js" /* webpackChunkName: "component---src-pages-english-certificates-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legislature-js": () => import("./../../../src/pages/legislature.js" /* webpackChunkName: "component---src-pages-legislature-js" */),
  "component---src-pages-timetable-js": () => import("./../../../src/pages/timetable.js" /* webpackChunkName: "component---src-pages-timetable-js" */)
}

