import PropTypes from "prop-types"
import React from "react"
import SideBar from './sideBar';
import Header from './header';

const ExternalLayout = ({ children, location }) => (
    <React.Fragment>
      <SideBar location={location}/>
      <div className="column is-paddingless" style={{overflowY: 'auto'}}>
          <Header />
          <section className="section" style={{backgroundColor: 'white', overflow: 'auto'}}> {/*Το overflow: 'auto' κάνει το περιεχόμενό του να μένει εντός*/}
            <div className="container is-fluid">
              <main>{children}</main>
            </div>
          </section>
      </div>
    </React.Fragment>
)

ExternalLayout.propTypes = {
  siteTitle: PropTypes.string,
}

ExternalLayout.defaultProps = {
  siteTitle: ``,
}

export default ExternalLayout
