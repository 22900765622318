import React from "react"
import { Link } from "gatsby"

function SideBar({location}){

  return(
    <React.Fragment>
      <div className="myMenu">
      <aside className="menu is-hidden-mobile is-hidden-tablet-only" style={{padding: '3em'}}> {/*is-hidden-mobile is-hidden-tablet-only*/}
        <p className="menu-label">
          ΠΛΟΗΓΗΣΗ
        </p>
        <ul className="menu-list">
          <li><Link to="/" className={location === '/' ? 'is-active' : ''}>Αρχική</Link></li>
          <li>
            
            <button id="egkyklios_button" className="button" style={{border: 'none', background: 'none', cursor: 'text'}}>Εγκύκλιος</button>
            <ul>
              <li><Link to="/bulletin/" className={location === '/bulletin/' ? 'is-active' : ''}>Κείμενο</Link></li>
              <li><Link to="/timetable/" className={location === '/timetable/' ? 'is-active' : ''}>Χρονοδιάγραμμα</Link></li>
              <li><Link to="/faq/" className={location === '/faq/' ? 'is-active' : ''}>Συχνές Ερωτήσεις</Link></li>
            </ul>
          </li>
          <li><Link to="/application-information/" className={location === '/application-information/' ? 'is-active' : ''}>Συμπλήρωση Αίτησης</Link></li>
          <li><Link to="/english-certificates/" className={location === '/english-certificates/' ? 'is-active' : ''}>Πτυχία Αγγλικών</Link></li>
          <li><Link to="/legislature/" className={location === '/legislature/' ? 'is-active' : ''}>Νομοθεσία</Link></li>
          <li><Link to="/contact/" className={location === '/contact/' ? 'is-active' : ''}>Επικοινωνία</Link></li>
        </ul>
      </aside>
            
      </div>
    </React.Fragment>
  );
}

export default SideBar
